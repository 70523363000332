@tailwind components;
@tailwind base;
@tailwind utilities;


:root {
  --primary-color: #004039;
  --secondary-color: #F46A25;
  --font-family: 'Montserrat', Helvetica, sans-serif !important;
}

html, body, #root {
  height: 100%;
}


@layer base {
  html {
    font-family: 'Montserrat', Helvetica, sans-serif !important;
  }
}

/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-100.woff') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local(''),
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../../../libs/shared/ui/src/lib/assets/fonts/montserrat-v25-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../../../libs/shared/ui/src/lib/assets/fonts/Raleway-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Air.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-AirItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-ThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../../../libs/shared/ui/src/lib/assets/fonts/Aeonik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

[data-name$="_score_summary_executive"] .grid {
  display: inline-flex;
}

[data-name$="_area_summary_executive"] {
  color: var(--secondary-color);
}

